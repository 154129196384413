import { AUTH_ACTION_TYPES } from './reducer';

export const LoginStartAction = (payload) => ({
  type: AUTH_ACTION_TYPES.PUT.START,
  payload
});

export const RegistrateStartAction = (payload) => ({
  type: AUTH_ACTION_TYPES.POST.START,
  payload
});

export const AuthResetAction = () => ({
  type: AUTH_ACTION_TYPES.RESET_STATE
});
