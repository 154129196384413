import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RouterLink from 'components/router/link';
import Spinner from 'components/spinner';
import FORM from 'components/forms';
import { withLoading } from 'redux/selectors';
import useNotification from 'hooks/useNotification.hook';
import { RegistrateStartAction, AuthResetAction } from '../redux/actions';
import { REGISTRATION_VALIDATION_SCHEMA } from '../constants';

const { Input, FormContainer } = FORM;

const Registration = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(withLoading('auth'));
  useNotification('auth');
  useEffect(
    () => () => {
      dispatch(AuthResetAction());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const initialValues = {
    firstName: '',
    patronymic: '',
    password: '',
    contact: '',
    passwordConfirmation: ''
  };
  const onSubmit = (values) => {
    // eslint-disable-next-line no-unused-vars
    const { passwordConfirmation, ...other } = values;
    dispatch(RegistrateStartAction(other));
  };
  return (
    <>
      <FormContainer
        enableReinitialize
        initialValues={initialValues}
        validationSchema={REGISTRATION_VALIDATION_SCHEMA}
        onSubmit={onSubmit}
        className="auth__form"
      >
        {(formik) => {
          const { isValid } = formik;
          return (
            <>
              <h2 className="auth__title">Регистрация</h2>
              <Input name="surname" type="text" label="Фамилия" />
              <Input name="firstName" type="text" label="Имя" />
              <Input name="patronymic" type="text" label="Отчество" />
              <Input name="contact" type="text" label="Электронная почта или телефон" />
              <Input name="password" type="password" label="Пароль" />
              <Input name="passwordConfirmation" type="password" label="Подтверждение пароля" />
              <button type="submit" className="auth__submit" disabled={!isValid}>
                {isLoading ? <Spinner /> : 'Регистрация'}
              </button>
            </>
          );
        }}
      </FormContainer>
      <RouterLink to="/">
        <button type="button" className="auth__btn">
          Вход
        </button>
      </RouterLink>
    </>
  );
};

export default Registration;
