import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationSelector } from 'redux/selectors';
import { NotificationResetAction } from 'redux/actions/notifications';
import './index.scss';

const Notificication = (props) => {
  const { activeClass, title, message, onClose } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    const closed = setTimeout(() => {
      onClose();
    }, 10000);
    return () => {
      dispatch(NotificationResetAction());
      clearTimeout(closed);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={`message ${activeClass}`}>
      <div className="wrapper">
        <div className="message__box">
          <span className="message__title">{title}</span>
          <span className="message__text">{message}</span>
          <button className="message__close" type="button" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

const Message = () => {
  const [activeClass, setActiveClass] = useState('');
  const { message, status, title } = useSelector(NotificationSelector);
  const onClose = () => {
    setActiveClass('');
  };

  useEffect(() => {
    setActiveClass(status);
  }, [status]);

  return (
    <>
      {activeClass && (
        <Notificication
          activeClass={activeClass}
          message={message}
          title={title}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default Message;
