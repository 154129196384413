import React from 'react';
import RouterLink from 'components/router/link';

const noMatch = () => (
  <>
    <div className="noMatch">
      <h1 className="noMatch__title">404</h1>
      <span className="noMatch__text">
        Запрашиваемая страница не найдена <br /> или была удалена
      </span>
      <RouterLink to="/">
        <button type="button" className="auth__submit">
          Войти
        </button>
      </RouterLink>
    </div>
  </>
);

export default noMatch;
