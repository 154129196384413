import React from 'react';
import moment from 'moment';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from 'components/layout';
import Auth from 'core/modules/auth';
import { store } from './redux';
import 'moment/locale/ru';
import 'assets/sass/auth.scss';

moment.locale('ru'); // important!

const App = () => (
  <Provider store={store}>
    <Router basename={process.env.REACT_APP_ROUTE_PREFIX || ''}>
      <Layout>{() => <Auth />}</Layout>
    </Router>
  </Provider>
);

export default App;
