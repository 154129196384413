import * as Yup from 'yup';

const emailRegex = /^([a-zA-Z0-9_.])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const phoneRegex = /^((8|\+7)[ ]?)?(\(?\d{3}\)?[ ]?)?[\d\- ]{7,10}$/;

export const LOGIN_VALIDATION_SCHEMA = Yup.object().shape({
  contact: Yup.string('Введите email или телефон')
    .required('Поле обязательно')
    .test(
      'contact',
      'Введите корректный email или телефон',
      (value) => !(!emailRegex.test(value) && !phoneRegex.test(value))
    )
    .typeError('Поле не должно быть числом'),
  password: Yup.string().required('Поле обязательно').typeError('Поле не должно быть числом')
});

export const REGISTRATION_VALIDATION_SCHEMA = Yup.object().shape({
  surname: Yup.string().required('Поле обязательно').typeError('Поле не должно быть числом'),
  firstName: Yup.string().required('Поле обязательно').typeError('Поле не должно быть числом'),
  patronymic: Yup.string().required('Поле обязательно').typeError('Поле не должно быть числом'),
  password: Yup.string()
    .min(6, 'Пароль должен быть длиной не менее 6 символов')
    .required('Поле обязательно')
    .typeError('Поле не должно быть числом'),
  passwordConfirmation: Yup.string()
    .required('Поле обязательно')
    .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать'),
  contact: Yup.string('Введите email или телефон')
    .required('Поле обязательно')
    .test(
      'contact',
      'Введите корректный email или телефон',
      (value) => !(!emailRegex.test(value) && !phoneRegex.test(value))
    )
    .typeError('Поле не должно быть числом')
});
