import React from 'react';
import { Field } from 'formik';

const Input = (props) => {
  const { label = '', type, name, onChange } = props;

  return (
    <Field {...props}>
      {({ field, form, meta }) => {
        const handleChange = (e) => {
          form.handleChange(e);
          // eslint-disable-next-line no-unused-expressions
          onChange && onChange(e);
        };
        const isError = !!(meta.error && meta.touched);
        const errorClass = isError ? 'error' : '';
        return (
          <div className="auth__field">
            {label && (
              <label htmlFor={name} className="auth__label">
                {label}
              </label>
            )}
            {isError && (
              <label htmlFor={name} className="auth__label--error error">
                {meta.error}
              </label>
            )}
            <input
              {...field}
              type={type}
              onChange={handleChange}
              id={name}
              className={`auth__input ${errorClass}`}
            />
          </div>
        );
      }}
    </Field>
  );
};

export default Input;
