import React from 'react';
import { Switch, Route } from 'react-router-dom';
import noMatch from 'components/noMatch';
import useAuthRedirect from 'hooks/useAuthRedirect.hook';
import Authorization from './components/auth';
import Registration from './components/registr';

const Auth = () => {
  useAuthRedirect();
  const docUrl = `${process.env.REACT_APP_API_URL || ''}/docs/policy`;
  return (
    <div className="auth__frame">
      <Switch>
        <Route exact path="/" component={Authorization} />
        <Route exact path="/registration" component={Registration} />
        <Route exact path="*" component={noMatch} />
      </Switch>
      <a className="auth__poly" href={`${docUrl}/conf.pdf`} target="_blank" rel="noreferrer">
        Политика конфиденциальности
      </a>
      <a className="auth__poly" href={`${docUrl}/personal.pdf`} target="_blank" rel="noreferrer">
        Политика обработки персональных данных
      </a>
    </div>
  );
};
export default Auth;
