import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RouterLink from 'components/router/link';
import FORM from 'components/forms';
import Spinner from 'components/spinner';
import { withLoading } from 'redux/selectors';
import useNotification from 'hooks/useNotification.hook';
import { LoginStartAction, AuthResetAction } from '../redux/actions';
import { LOGIN_VALIDATION_SCHEMA } from '../constants';

const { Input, FormContainer } = FORM;
const Authorization = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(withLoading('auth'));
  useNotification('auth');
  useEffect(
    () => () => {
      dispatch(AuthResetAction());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const initialValues = {
    email: '',
    password: ''
  };

  const onSubmit = (values) => {
    dispatch(LoginStartAction(values));
  };
  return (
    <>
      <FormContainer
        enableReinitialize
        initialValues={initialValues}
        validationSchema={LOGIN_VALIDATION_SCHEMA}
        onSubmit={onSubmit}
        className="auth__form"
      >
        {(formik) => {
          const { isValid } = formik;
          return (
            <>
              <h2 className="auth__title">Вход</h2>
              <Input name="contact" type="text" label="Электронная почта или телефон" />
              <Input name="password" type="password" label="Пароль" />
              <button type="submit" className="auth__submit" disabled={!isValid}>
                {isLoading ? <Spinner /> : 'Вход'}
              </button>
            </>
          );
        }}
      </FormContainer>
      <RouterLink to="/registration">
        <button type="button" className="auth__btn">
          Регистрация
        </button>
      </RouterLink>
    </>
  );
};

export default Authorization;
