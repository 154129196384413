import { call, put, takeLatest } from 'redux-saga/effects';
import SERVICE_API from 'api';
import { AUTH_ACTION_TYPES } from './reducer';

function* doLogin(action) {
  try {
    const { data } = yield call(SERVICE_API.AuthAPI.login, action.payload);
    yield put({
      type: AUTH_ACTION_TYPES.PUT.SUCCESS,
      payload: data
    });
  } catch ({ response }) {
    yield put({
      type: AUTH_ACTION_TYPES.PUT.ERROR,
      payload: response.data
    });
  }
}

function* doRegistrate(action) {
  try {
    const { data } = yield call(SERVICE_API.AuthAPI.registration, action.payload);
    yield put({
      type: AUTH_ACTION_TYPES.POST.SUCCESS,
      payload: data
    });
  } catch ({ response }) {
    yield put({
      type: AUTH_ACTION_TYPES.POST.ERROR,
      payload: response.data
    });
  }
}

export function* AuthSaga() {
  yield takeLatest(AUTH_ACTION_TYPES.PUT.START, doLogin); // функция doLogin отработает тогда когда отработает экшен PUT/START
  yield takeLatest(AUTH_ACTION_TYPES.POST.START, doRegistrate);
}
