import React from 'react';

const Spinner = () => (<span className="spinner">
    <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="17" cy="17" r="15" stroke="white"/>
    </svg>
</span>)
export default Spinner;