import API from 'api/restApiService';

class AuthAPI {
  login = (payload) => API.put('/api/auth/sign-in', payload);

  registration = (payload) => API.post('/api/auth/sign-up', payload);

  code = (payload) => API.get(`/api/auth/token?code=${payload}`);
}

export default new AuthAPI();
