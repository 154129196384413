import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withLoading } from 'redux/selectors';
import { NotificationOpenAction, NotificationResetAction } from 'redux/actions/notifications';

const useNotification = (module) => {
  const dispatch = useDispatch();
  const { error } = useSelector(withLoading(module));

  useEffect(() => {
    if (error) {
      dispatch(
        NotificationOpenAction({
          status: 'danger',
          message: error
        })
      );
    }
    return () => {
      dispatch(NotificationResetAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // useEffect(
  //   () => () => {
  //     dispatch(NotificationResetAction());
  //   },
  //   []
  // );
};

export default useNotification;
