import React from 'react';
import Logo from 'components/logo/index';
import Message from 'components/message';

const Layout = (props) => {
  const { children, ...other } = props;
  return (
    <main className="auth">
      <div className="wrapper">
        <div className="auth__box">
          <Message />
          <Logo path={process.env.REACT_APP_PLATFORM_URL || ''} />
          {children(other)}
        </div>
      </div>
    </main>
  );
};

export default Layout;
