import React from 'react';
import { useHistory } from 'react-router-dom';

const RouterLink = (props) => {
  const { to, children } = props;
  const history = useHistory();
  const onClick = () => {
    history.push({ pathname: to, search: history.location.search });
  };
  const elem = React.createElement(
    children.type,
    { onClick, ...children.props },
    children.props.children
  );
  return elem;
};

export default RouterLink;
