import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { linkSelector } from 'core/modules/auth/redux/selectors';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Cookies from 'js-cookie';
import SERVICES_URLS from 'constants/SERVICES_URLS';

const useAuthRedirect = (props = { paramName: 'url' }) => {
  const { paramName } = props;
  const history = useHistory();
  const { token, lifeTime } = useSelector(linkSelector);
  useEffect(() => {
    if (token) {
      const urlParams = new URLSearchParams(history.location.search);
      const dayInSec = 86400;
      const d = moment.duration(lifeTime || dayInSec, 'seconds');
      const cookieDays = d.asDays();
      const redirectUrl = urlParams.get(paramName);
      const developMock = urlParams.get('develop');
      const { domain, platform } = SERVICES_URLS;
      const cookieDomain = developMock ? 'localhost' : domain;
      Cookies.set('token', token, { path: '/', domain: cookieDomain, expires: cookieDays });
      if (redirectUrl) {
        window.location = redirectUrl;
      } else {
        window.location = platform;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
};

export default useAuthRedirect;
